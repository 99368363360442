@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-Medium.eot');
  src: url('./fonts/GeneralSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/GeneralSans-Medium.woff2') format('woff2'),
    url('./fonts/GeneralSans-Medium.woff') format('woff'),
    url('./fonts/GeneralSans-Medium.ttf') format('truetype'),
    url('./fonts/GeneralSans-Medium.svg#GeneralSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'General Sans';
  src: url('./fonts/GeneralSans-Semibold.eot');
  src: url('./fonts/GeneralSans-Semibold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/GeneralSans-Semibold.woff2') format('woff2'),
    url('./fonts/GeneralSans-Semibold.woff') format('woff'),
    url('./fonts/GeneralSans-Semibold.ttf') format('truetype'),
    url('./fonts/GeneralSans-Semibold.svg#GeneralSans-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('./fonts/NeueMontreal-Medium.eot');
  src: url('./fonts/NeueMontreal-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NeueMontreal-Medium.woff2') format('woff2'),
    url('./fonts/NeueMontreal-Medium.woff') format('woff'),
    url('./fonts/NeueMontreal-Medium.ttf') format('truetype'),
    url('./fonts/NeueMontreal-Medium.svg#NeueMontreal-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('./fonts/NeueMontreal-Regular.eot');
  src: url('./fonts/NeueMontreal-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NeueMontreal-Regular.woff2') format('woff2'),
    url('./fonts/NeueMontreal-Regular.woff') format('woff'),
    url('./fonts/NeueMontreal-Regular.ttf') format('truetype'),
    url('./fonts/NeueMontreal-Regular.svg#NeueMontreal-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-Bold.eot');
  src: url('./fonts/Manrope-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Manrope-Bold.woff2') format('woff2'),
    url('./fonts/Manrope-Bold.woff') format('woff'),
    url('./fonts/Manrope-Bold.ttf') format('truetype'),
    url('./fonts/Manrope-Bold.svg#Manrope-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist';
  src: url('./fonts/Geist-SemiBold.eot');
  src: url('./fonts/Geist-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Geist-SemiBold.woff2') format('woff2'),
    url('./fonts/Geist-SemiBold.woff') format('woff'),
    url('./fonts/Geist-SemiBold.ttf') format('truetype'),
    url('./fonts/Geist-SemiBold.svg#Geist-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.default-loader-size {
  @apply h-16 border-4 w-16;
}

.small-loader-size {
  @apply h-10 border-2 w-10;
}

.container {
  @apply max-w-6xl mx-auto px-6 2xl:px-10;
}

.aspect-video-block {
  aspect-ratio: 16 / 9;
}

input:not([type='radio']):not([type='checkbox']):not([type='button']):not([type='submit']):not([type='reset']):not([type='file']):-webkit-autofill,
input:not([type='radio']):not([type='checkbox']):not([type='button']):not([type='submit']):not([type='reset']):not([type='file']):-webkit-autofill:focus,
input:not([type='radio']):not([type='checkbox']):not([type='button']):not([type='submit']):not([type='reset']):not([type='file']):-webkit-autofill:focus:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  filter: none; /* u need this for firefox */
  transition: background-color 5000s ease-in-out 0s;
}

.change-plus-icon:hover .change-background-color-plus {
  background-color: rgba(67, 27, 59,0.3);
}

/* #scroll-to-top-button {
  @media screen and (max-width: 575.98px) {
    bottom: 70px;
    right: 15px;
    padding: 0.5rem;
  }
} */

.main-spinner-view {
  /* 
  black-violet
  border-top-color: #431b3b;
  border-right-color: #431b3b;
  border-bottom-color: #431b3b; */

  /* light-violet */
  border-top-color: #ac508b;
  border-right-color: #ac508b;
  border-bottom-color: #ac508b;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

/* markdown */

.text-lg .wmde-markdown {
  font-size: 18px !important;
}

.text-md .wmde-markdown {
  font-size: 16px !important;
  font-weight: normal !important;
}

.wmde-markdown {
  font-size: 14px !important;
}

/* Overriding tailwind base for markdown preview */
.wmde-markdown ol {
  list-style: decimal;
}

.w-md-editor {
  box-shadow: none !important;
}

.wmde-markdown-color {
  background-color: inherit !important;
}

.bg-transparent.w-md-editor {
  background-color: transparent !important;
}

.w-md-editor-preview {
  padding: 8px 10px !important;
}

/* select, multiselect, sortable */

/* .css-1rhbuit-multiValue {
  z-index: 9999 !important;
} */

input.focus-visible[type='text']:focus {
  box-shadow: none;
}

/* PQ Slider */

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ab508b;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ab508b;
  cursor: pointer;
}

.StripeElement {
  border: 1px solid #ab508b;
  padding: 12px;
  border-radius: 4px;
}

/* react-sortable-hoc + react-select item inside a modal would hide on drag */
/* NOTE if the library is compiled and this class changes, this class should update */
/* TOOD: add a class to all items/use a new library */
.css-1rhbuit-multiValue {
  z-index: 10;
}

.lesson-draggable-title-clamp {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* #searchie-fixed-btn {
  display: none !important;
} */

.wmde-markdown > * {
  @apply text-base leading-24 font-base text-dark-400;
}

.wmde-markdown iframe {
  max-width: 100%;
  width: 100%;
  aspect-ratio: 16 / 9;
  height: 100%;
}

.wmde-markdown ul {
  list-style: circle;
}

.ai-tool-iframe {
  min-height: 700px; 
  height: 100%;
}


.wysiwyg .wmde-markdown p,
.wysiwyg .wmde-markdown ul li,
.wysiwyg .wmde-markdown ol li {
  @apply text-base xl:text-md leading-24 xl:leading-28 font-base tracking-1;
}

.wysiwyg .wmde-markdown,
.wysiwyg .wmde-markdown a {
  @apply text-dark-500;
}

.wysiwyg .wmde-markdown a {
  @apply underline;
}

.wysiwyg .wmde-markdown a:hover {
  @apply text-secondary;
}

.wysiwyg .wmde-markdown strong {
  @apply font-medium;
}

.wysiwyg .wmde-markdown h1,
.wysiwyg .wmde-markdown h2,
.wysiwyg .wmde-markdown h3,
.wysiwyg .wmde-markdown h4 {
  @apply text-dark tracking-1 mt-8 mb-3.5 border-none pb-0;
}

.wysiwyg .wmde-markdown h1 {
  @apply text-md-5 leading-32;
}

.wysiwyg .wmde-markdown h2 {
  @apply text-md-3 leading-28;
}

.wysiwyg .wmde-markdown h3 {
  @apply text-md-2 leading-25;
}

.wysiwyg .wmde-markdown h1[id] a,
.wysiwyg .wmde-markdown h2[id] a,
.wysiwyg .wmde-markdown h3[id] a {
  @apply ml-0 pr-0 mr-1 inline-block leading-28 transition-colors cursor-pointer;
}

.wysiwyg .wmde-markdown h1[id] a {
  @apply leading-32;
}

.wysiwyg .wmde-markdown h2[id] a {
  @apply leading-28;
}

.wysiwyg .wmde-markdown h3[id] a {
  @apply leading-25;
}

.wysiwyg .wmde-markdown h1[id] a svg,
.wysiwyg .wmde-markdown h2[id] a svg,
.wysiwyg .wmde-markdown h3[id] a svg {
  @apply visible text-white-400;
}

.wysiwyg .wmde-markdown h1[id] a:hover svg,
.wysiwyg .wmde-markdown h2[id] a:hover svg,
.wysiwyg .wmde-markdown h3[id] a:hover svg {
  @apply text-secondary;
}

.wysiwyg .wmde-markdown h1,
.wysiwyg .wmde-markdown h1 strong,
.wysiwyg .wmde-markdown h2,
.wysiwyg .wmde-markdown h2 strong,
.wysiwyg .wmde-markdown h3,
.wysiwyg .wmde-markdown h3 strong {
  @apply font-semibold;
}

.wysiwyg .wmde-markdown p {
  @apply mb-2.5;
}

.wysiwyg .wmde-markdown hr {
  @apply h-0 bg-transparent border-b border-solid border-white-400;
}

.wysiwyg .wmde-markdown ul,
.wysiwyg .wmde-markdown ol {
  @apply mt-3.5 mb-8 pl-0 ml-6;
}

.wysiwyg .wmde-markdown ul {
  @apply list-disc;
}

.wysiwyg .wmde-markdown ol li::marker {
  @apply font-semibold;
}

.wysiwyg .wmde-markdown li + li {
  @apply mt-0;
}

.wysiwyg .wmde-markdown iframe {
  @apply rounded-2xl;
  width: calc(100% + 28px);
}

.wysiwyg .wmde-markdown img {
  @apply my-8;
}
